.linked-title-rich-text__link-item {
  box-sizing: border-box;
  padding-top: 30px;
  padding-left: 0px;
  width: 100%;
  /* needs to be full width */
  /* https://productiveedge.atlassian.net/browse/PPM-462 */
  /* padding-right: 30px;  */
  /* max-width: 425px; */
}

.linked-title-rich-text__link-item-name-wrapper {
  margin-bottom: 18px;
}
.linked-title-rich-text__link-item-name-wrapper a {
  letter-spacing: 3px;
}

@media only screen and (min-width: 992px) {
  .linked-title-rich-text__link-items {
    padding: 0;
  }
}
