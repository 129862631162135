div.backgroundImage {
  background-repeat: no-repeat;
  /* calc of 100% is required to div to take up full height of parent container */
  height: calc(100%);
  overflow: hidden;
  background-size: cover;
}

/* For mobile large header in one column override of bottom position set*/
div.backgroundImage.top-center{
  	background-position: center top;
}

div.backgroundImage.top-left{
  	background-position: left top;
}

div.backgroundImage.top-right{
  	background-position: right top;
}

div.backgroundImage.bottom-left{
  	background-position: left bottom;
}

div.backgroundImage.bottom-right{
    background-position: right bottom;
}

div.backgroundImage.center{
  background-position: center center;
}