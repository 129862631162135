.side-nav {
    text-align: left;
}

.side-nav__title-container {
    padding-bottom: 20px;
    /* margin-bottom: 20px; */
    /* Since text is centered in the container the margin bottom has been adjusted to ensure 20px spacing between dash and content above and below */
    margin-bottom: 12px;
    position: relative;
}
.side-nav__title {
    color: #6a737b;
    margin-bottom: 0;
    /* Adjustment to line height was added so that 
    any g/p/y char doesn't change 20px spacing under it */
    line-height: .75;
}

.side-nav__title-container::after {
    content: " ";
    display: block;
    height: 1px;
    width: 24px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #25282a;
}

@media(max-width: 576px) {
    .side-nav__title-container {
        padding-bottom: 20px;
        margin-bottom: 13px;
        position: relative;
    }
    .side-nav__title {
        color: #6a737b;
        margin-bottom: 0;
        line-height: .75;
    }
}