.one-column-rich-text__wrapper {
    padding: 30px, 30px, 30px, 0px;
}

.one-column-rich-text__title-container {
    margin-bottom: 30px;
}

.one-column-rich-text__title {
    font-family: "AzoSans-Bold", Arial, "Helvetica Neue", "Helvetica", "sans-serif";
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 1.234;
    text-transform: uppercase;
}

.one-column-rich-text__textarea {
    font-family: "AzoSans-Light", Arial, "Helvetica Neue", "Helvetica", "sans-serif";
    color: #25282a;
}

@media only screen and (max-width: 768px) {
    .one-column-rich-text__title {
        font-size: 16px;
    }
    .one-column-rich-text__textarea {
        font-size: 16px;
    }
    
}

/* PPM-380 */
/* add link styles to the rich text section */
.one-column-rich-text__textarea a{
    color: #5C068C;
}
.one-column-rich-text__textarea a:hover{
    text-decoration: none;
    color: #25282a;
}
