/* top level container */
.stats-ui-container {
  color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*general styles across breakpoints*/
.stats-ui-group-title {
  font-family: "AzoSans-Bold";
  font-size: 1.25em;
  align-self: flex-start;
  margin-top: 1.5em;
  /* (20px) */
  text-transform: uppercase;
}

/* Divider */
.statistics-ui-divider {
  opacity: 0.5;
  background-color: white;
  height: 2px;
  width: 100%;
}

/*label and number if there is a group title */
.stats-ui-group-stats-container-with-title .stats-item-label {
  /* heading 4 all caps */
  font-family: "AzoSans-Medium";
  font-size: 1em;
  letter-spacing: 2.29px;
  text-transform: uppercase;
  text-align: center;
}
.stats-ui-group-stats-container-with-title .stats-item-number {
  /* heading 1 */
  font-family: "CenturyStd-Book";
  font-size: 3em;
  /* (48px) */
}
/* No group title label and number */
.stats-ui-group-stats-container-no-group-title .stats-item-label {
  /* heading 4 all caps */
  font-family: "AzoSans-Medium";
  font-size: 1.125em;
  letter-spacing: 2.58px;
}
.stats-ui-group-stats-container-no-group-title .stats-item-number {
  /* display 1 */
  font-family: "CenturyStd-Book";
  font-size: 7em;
  /*  (112px) */
}

/* Global Styles */
.stats-item-number {
  text-align: center;
}
.stats-item-label {
  text-transform: uppercase;
  text-align: center;
  word-wrap: normal;
}

/*Mobile Styles*/
@media (max-width: 576px) and (min-width: 0px){
  .stats-ui-group-container {
    display: flex;
    flex-direction: column;
    padding-top: 2.5em;
    padding-bottom: 3em;
  }
  .stats-ui-group-title {
    text-transform: uppercase;
    text-align: center;
    max-width: 15em;
    word-wrap: normal;
    margin-bottom: 2em;
  }
  
  .stats-ui-container {
    padding: 0px;
  }
  .stats-ui-group-stats-container-no-group-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  /*NO TITLE -labels - number - superscript  */
  .stats-ui-group-stats-container-no-group-title .stats-item-label {
    text-transform: uppercase;
    text-align: center;
    word-wrap: normal;
    margin: auto;
    /* Close the gap between the number and the label */
    margin-top: -2.1em;
    /* margin-bottom: 25px; */
  }
  .stats-ui-group-stats-container-no-group-title .stats-item-number-currency {
    font-size: 70px;
    vertical-align: text-top;
  }
  .stats-ui-group-stats-container-no-group-title .stats-item-number-percent {
    font-size: 0.625em;
    vertical-align: text-top;
  }
  .stats-ui-group-stats-container-no-group-title
    .stats-item-number-superscript {
    font-size: 0.5em;
    vertical-align: top;
  }
  /*WITH TITLE*/
  .stats-ui-group-stats-title-container{
    padding-top: 2.1em;
    padding-bottom: 2.1em;
  }
  .stats-ui-group-stats-container-with-title {
    display: grid;
    grid-template-columns: fit-content(50%) fit-content(50%);
    grid-gap: 3.2em 1.5em;
  }
  .stats-ui-group-stats-container-with-title > .stats-item-container {
    display: grid;
    grid-template-rows: fit-content(50%) fit-content(50%);
    /* max-width: 50%;
      padding: 5px; */
  }
  .stats-ui-group-stats-container-with-title .stats-item-label {
    max-width: 8em;
    word-wrap: normal;
    margin: auto;
    line-height: 1.2;
    margin-top: -.8em;
  }
  .stats-ui-group-stats-container-with-title .stats-item-number-currency {
    font-size: 0.625em;
    vertical-align: text-top;
  }
  .stats-ui-group-stats-container-with-title .stats-item-number-percent {
    font-size: 0.625em;
    vertical-align: text-top;
  }
  .stats-ui-group-stats-container-with-title .stats-item-number-superscript {
    font-size: 0.5em;
    vertical-align: top;
  }
   /*With title - Custom spacing styles depending on number of stats displayed */
  
   .stats-ui-group-stats-container-with-title .stats-item-container{
    max-width: 9em;
  }
  .stats-ui-group-stats-container-with-title.ppm-row-margin-for-one-stat{
     display: grid;
     justify-content: center;
     grid-template-columns: repeat(1, auto); 
  }
  /*No Title - Custom spacing styles depending on number of stats displayed */
  .stats-ui-group-stats-container-no-group-title .stats-item-container{
    /* max-width: 13em; */
    
    margin-bottom:1em;
  }
}

/* Tablet Styles */
@media (min-width: 577px) and (max-width: 992px){
  .stats-ui-container {
    width: 100%;
  }
  .stats-ui-group-container {
    padding-top: 30px;
    padding-bottom: 50px;
    width: 100%;
  }
  .stats-ui-group-title {
    text-transform: uppercase;
    text-align: left;
    word-wrap: normal;
    max-width: 15em;
    word-wrap: normal;
  }
  /* NO TITLE */
  .stats-ui-group-stats-container-no-group-title {
    display: grid;
    grid-template-columns: fit-content(50%) fit-content(50%);
    grid-gap: 0em;
    justify-content: space-around;
    /* move to based on number of stat items */
    /* margin-top: 3em;
    margin-bottom: 1.8em; */
  }
  .stats-ui-group-stats-container-no-group-title .stats-item-label {
    text-transform: uppercase;
    text-align: center;
    /* This needs to be here to meet design specs but
    design specs don't account for dynamic text and the possibility of 
    text collision */
    /* white-space: nowrap;  */
    line-height: 1.2;
     /* Close the gap between the number and the label */
     margin-top: -1em;
  }
  .stats-ui-group-stats-container-no-group-title .stats-item-number-currency {
    font-size: 0.625em;
    vertical-align: text-top;
  }
  .stats-ui-group-stats-container-no-group-title .stats-item-number-percent {
    font-size: 0.625em;
    vertical-align: text-top;
  }
  .stats-ui-group-stats-container-no-group-title
    .stats-item-number-superscript {
    font-size: 0.5em;
    vertical-align: top;
  }
  /* WITH TITLE */
  .stats-ui-group-title {
    text-transform: uppercase;
    text-align: left;
    word-wrap: normal;
    max-width: 15em;
    word-wrap: normal;
    margin-bottom: 1.5em;
    letter-spacing: 1px;
  }
  .stats-ui-group-stats-title-container{
    margin-top: 1em;
    padding-bottom: 5px;
  }
  .stats-ui-group-stats-container-with-title .stats-item-label {
    text-transform: uppercase;
    /* text-align: center; */
    /* max-width: 3em; */
    word-wrap: normal;
    line-height: 1.2;
     /* Close the gap between the number and the label */
     margin-top: 5px;
  }
  .stats-ui-group-stats-container-with-title .stats-item-number-currency {
    font-size: 0.625em;
    vertical-align: text-top;
  }
  .stats-ui-group-stats-container-with-title .stats-item-number-percent {
    font-size: 0.625em;
    vertical-align: text-top;
  }
  .stats-ui-group-stats-container-with-title .stats-item-number-superscript {
    font-size: 0.5em;
    vertical-align: top;
  }
  /*With title - Custom spacing styles depending on number of stats displayed */
  .stats-ui-group-stats-container-with-title .stats-item-container{
    max-width: 9em;
  }
  .stats-ui-group-stats-container-with-title.ppm-row-margin-for-one-stat{
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(1, auto);
  }
  .stats-ui-group-stats-container-with-title.ppm-row-margin-for-two-stats{
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-around;
  }
  .stats-ui-group-stats-container-with-title.ppm-row-margin-for-three-stats{
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-around;
  }
  .stats-ui-group-stats-container-with-title.ppm-row-margin-for-four-stats{
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-content: space-around;
  }
  /*No Title - Custom spacing styles depending on number of stats displayed */
  .stats-ui-group-stats-container-no-group-title .stats-item-container{
    max-width: 13em;
  }
  .stats-ui-group-stats-container-no-group-title.ppm-row-margin-for-one-stat{
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(1, auto);
  }
}

/* Desktop styles */
@media (min-width: 992px) {
  .stats-ui-container {
    width: 100%;
  }
  .stats-ui-group-container {
    max-width: 1110px;
    width: 100%;
  }
  .stats-ui-group-stats-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .statistics-ui-divider{
    max-width: 1110px;
  }
  /* NO TITLE */
  .stats-ui-group-stats-container-no-group-title {
    display: flex;
    justify-content: space-between;
  }

  .stats-ui-group-title {
    text-align: left;
    max-width: 15em;
    word-wrap: pre-line;
    display: inline-block;
  }
  /* WITH NO TITLE */
  .stats-ui-group-stats-container-no-group-title .stats-item-number {
    text-align: center;
  }
  .stats-ui-group-stats-container-no-group-title .stats-item-label {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0em;
    max-width: 11em;
    white-space: normal;
    /* Close the gap between the number and the label */
    margin-top: -1em;
  }
  .stats-ui-group-stats-container-no-group-title .stats-item-number-currency {
    font-size: 0.625em;
    vertical-align: text-top;
  }

  .stats-ui-group-stats-container-no-group-title .stats-item-number-percent {
    font-size: 0.625em;
    vertical-align: text-top;
  }

  .stats-ui-group-stats-container-no-group-title .stats-item-number-superscript {
    font-size: 0.5em;
    vertical-align: top;
  }
  /* WITH TITLE */
  .stats-ui-group-stats-title-container{
    display: grid;
    grid-template-columns: 35% auto;
    margin-bottom: 0;
  }
  .stats-ui-group-stats-title-container{
    padding-top: 3em;
  }
  .stats-ui-group-stats-container-with-title {
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-content: space-around;
    margin: 0;
  }
  .stats-ui-group-stats-container-with-title .stats-item-label {
    text-transform: uppercase;
    word-wrap: normal;
    line-height: 1.2;
     /* Close the gap between the number and the label */
     margin-top: -5px;
  }
  .stats-ui-group-stats-container-with-title .stats-item-number-currency {
    font-size: 0.625em;
    vertical-align: text-top;
  }
  .stats-ui-group-stats-container-with-title .stats-item-number-percent {
    font-size: 0.625em;
    vertical-align: text-top;
  }
  .stats-ui-group-stats-container-with-title .stats-item-number-superscript {
    font-size: 0.5em;
    vertical-align: top;
  }
  /*With title - Custom spacing styles depending on number of stats displayed */
  
  .stats-ui-group-stats-container-with-title .stats-item-container{
    max-width: 10em;
    /*  */
  }
  .stats-ui-group-stats-container-with-title.ppm-row-margin-for-one-stat{
    display: grid;
    justify-content: space-around;
    margin: 1em 0em 2em 0em;
    grid-template-columns: repeat(1, auto);
  }
  .stats-ui-group-stats-container-with-title.ppm-row-margin-for-two-stats{
    display: grid;
    margin: 1em 7em 2em 7em;
    grid-template-columns: repeat(2, auto);
  }
  .stats-ui-group-stats-container-with-title.ppm-row-margin-for-three-stats{
    display: grid;
    margin: 1em 3em 2em 3em;
    grid-template-columns: repeat(3, auto);
  }
  .stats-ui-group-stats-container-with-title.ppm-row-margin-for-four-stats{
    display: grid;
    margin: 1em 0em 2em 0em;
    grid-template-columns: repeat(4, auto);
  }
  /*No Title - Custom spacing styles depending on number of stats displayed */
  .stats-ui-group-stats-container-no-group-title .stats-item-container{
    max-width: 13em;
  }
  .stats-ui-group-stats-container-no-group-title.ppm-row-margin-for-one-stat{
    display: grid;
    justify-content: space-around;
    margin: 1em 0em 2em 0em;
    grid-template-columns: repeat(1, auto);
  }
  .stats-ui-group-stats-container-no-group-title.ppm-row-margin-for-two-stats{
    display: grid;
    margin: 1em 15em 2em 15em;
    grid-template-columns: repeat(2, auto);
  }
  .stats-ui-group-stats-container-no-group-title.ppm-row-margin-for-three-stats{
    display: grid;
    justify-content: space-around;
    margin: 1em 0em 2em 0em;
    grid-template-columns: repeat(3, auto);
  }
  .stats-ui-group-stats-container-no-group-title.ppm-row-margin-for-four-stats{
    display: grid;
    margin: 1em 0em 2em 0em;
    grid-template-columns: repeat(4, auto);
  }
  
}
