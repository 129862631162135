.not-found-page #ppm-nav-desktop > ul > li:last-child {
	margin-left: initial;

}

.top-msg-box {
	background-color: #004f59;
    background-size: 100% 100%;
    padding: 40px 10px;
}

.top-msg-wrapper {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

.top-msg {
	color: #ffffff;
}

.text-wrapper {
	width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 75px 10px;
}

@media (max-width: 576px) {
	.text-wrapper {
    	padding: 20px 10px;
	}
}
