


/* Utility */
.buffer, .row.footer-container.buffer {
	padding:24px 0 4px;
}

#root > .container, .container {
	/* Temporatily commented out by Dmitri Larionov to preserve container paddings on narrow viewports */
	/* container styles shouldn't be altered, instead create a custom class and use that to create custom behavior */
	/* width:100%;
	height:auto;
	margin:0;
	padding-right:0;
	padding-left:0;
	max-width:none;
	overflow-x: hidden; */
}

/* footer styles */
footer {
	background:#FFF;
	color:#25282a;
	height:auto;
	width:100%;
	margin:0;
	padding-right:0;
	padding-left:0;
}

.footer-content{
	width:100%;
	padding:0;
	margin:0;
	min-height:149px;
	height:auto;
	background:#F4F4F4;
}

.row.footer-container, .footer-container {
	width:100%;
	max-width:100%;
	margin-right:auto;
	margin-left:auto;
}

.footer-logo{}

img.ppm-logo{
	width:100%;
	min-width:213px;
	height:32px;
}


img.ppm-logo,
.footer-container > .address > ul,
.footer-container > .address > ul > li,
.social-icons img,
.footer-container > .footer-links > ul,
.footer-container > .footer-links > ul > li  {
	padding-inline-start:0;	/* Adjusts Alignment for all Footer content on Mobile */
}

.footer-container > .address > ul > li, 
.footer-container > .footer-links > ul > li {
	display:-webkit-inline-box; /* Adds box constraints for address-content breaks */
}

.address, .footer-links, .footer-links > ul > li > a , .copyright {
	color: #25282a;
}

.footer-links, .copyright {
	font-family:AzoSans-Light, Helvetica, Arial, sans-serif;
}

.address {
	font-size: 14px;
	line-height: 24px;
	font-family: CenturyStd-Book, Georgia, Times, serif;
}

.address > ul {
	margin-bottom:0;
	max-width:95%;
}

.address > ul > li, 
.footer-links > ul > li {
	list-style-type:none;
	display: initial;
	padding:0 8px;
}


.social-icons > a, .social-icons > a:hover,
.social-icons > a:focus, .social-icons > a:active  {
	text-decoration:none;
	color:transparent;
}
.social-icons > a > svg {
	margin-left:16px;
}

.copyright {
	text-align:left;
	text-indent:0;
}

.copyright > h6, .footer-links > ul > li > a {
	font-size:12px;
}

.copyright > h6 {
	line-height: 0;
	padding:0 0 20px;
}

.footer-links > ul {
	text-align: left;
	line-height:0;
}

.footer-container > .footer-links > ul > li {
	padding:0 8px;
	padding-inline-start:inherit;
}

.footer-links > ul > li > a {
	line-height:18px;
}


/* === Media Queries === */


@media (min-width: 576px) {
  	img.ppm-logo { 
  		padding-inline-start:0%; /* Fixes alignment of PPM Logo to match text-content */
  	}
  	.footer-container > .address > ul > li,
  	.footer-container > .footer-links > ul > li {
  		display:initial;  /* Fixes line height for text content for footer address text & footer links */
  	}
	.footer-container > .address > ul > li,
	.footer-container > .footer-links > ul > li {
		padding-inline-start:0%; /* Fixes alignment for text-based content. Excludes: '.COPYRIGHT' & '.ADDRESS > UL' content. */
	}
	.footer-container > .copyright > h6 { 
		padding-inline-start:0%; /* Fixes alignment for '.COPYRIGHT' text ONLY */ 
	}
  	.address {
  		font-size:15px;
		letter-spacing:0;
	}
	.footer-container > .address > ul {
		padding-top:0;
		padding-inline-start:0%; /* Fixes alignment for '.ADDRESS > UL' text ONLY ++++++ 1.22% is equal to 6px */ 
		max-width:100%;
	}
	.footer-container > .address > ul > li {
		padding:0 8px;
		padding-inline-start:0%;
	}
	.social-icons {
		padding-bottom:0;
	}
	.social-icons img {
		padding-inline-start:inherit; /* Keeps linkedin icon aligned with search icon on right side */
	}
	.copyright > h6, .footer-links > ul > li > a {
		font-size:14px;
	}
	.copyright > h6 {
  		line-height:24px;
  		padding:0 0 8px;
  	}
	.footer-container > .footer-links > ul {
		padding-inline-start:0%;
  	}
  	.footer-links > ul > li > a {
  		line-height:0px;
  	}
}

@media (min-width: 768px) {

  	img.ppm-logo,
  	.footer-container > .address > ul,
	.footer-container > .copyright > h6,
	.footer-container > .footer-links > ul {
		padding-inline-start:0;	/* Fixes alignment for text-based content. INCLUDES copyright content. */
	}
	.footer-container > .address > ul > li {
		padding: 0 12px;
		padding-inline-start:0; /* Needs to be listed with the padding style to properly execute */
	}
	.footer-container > .footer-links > ul > li {
		padding:0 16px;
		padding-inline-start:0; /* Needs to be listed with the padding style to properly execute */
	}
  	.address {
  		font-size:16px;
  		letter-spacing:0.10px;
  		line-height:32px;
	}
	.social-icons img {
		padding-inline-start:30%; /* Keeps linkedin icon aligned with search icon on right side */
	}

	.footer-links > ul > li {
		padding:0 8px;
	}
}

@media (min-width: 992px) {
	.row.footer-container.buffer{
		padding:24px 0 4px;
	}
  	.address {
  		font-size:18px;
	}
	.address > ul {
		padding-inline-start:0;
	}
	.social-icons img {
		padding-inline-start:0%; /* aligns linkedin icon to match 'social' link */
	}
	.copyright > h6, .footer-links > ul > li > a {
		font-size:16px;
	}
  	.footer-container > .footer-links > ul {
  		text-align:right;
  		padding-inline-start:0;
  		line-height:24px;
  	}
  	.footer-container > .footer-links > ul > li {
  		padding: 0 16px;
  		padding-inline-start:0;
  		padding-inline-end:5%;

  	}
}

@media (min-width: 1200px) {
  	.footer-container > .address > ul {
		padding-inline-start:2.68%;
	}
	.footer-container > .address > ul > li {
		padding: 0;
		padding-inline-start:inherit;
	}
	.social-icons img {
		padding-inline-start:20%; /* aligns linkedin icon to match 'social' link */
	}
  	.footer-container > .footer-links > ul {
  		text-align:right;
  		padding-inline-start:0;
  	}
}

@media (min-width: 1400px) {
	.row.footer-container, .footer-container {
		max-width:1440px;
	}
  	img.ppm-logo,
	.footer-container > .address > ul,
	.footer-container > .copyright > h6,
	.footer-container > .footer-links > ul,
	.footer-container > .footer-links > ul > li {
		padding-inline-start:0;	/* Fixes alignment for text-based content. INCLUDES copyright content. */
	}
	.footer-container > .address > ul > li {
		padding:0 8px;
	}
  	.social-icons img {
		padding-inline-start:30%;  /* aligns linkedin icon to match search icon/maginifying glass */
	}
	.footer-container > .footer-links > ul { 
		padding-inline-start:9%; /* aligns 'social' link with linkedin icon */
		padding-inline-end:2.6%;
	}
  	.footer-container > .footer-links > ul > li {
  		padding: 0 13px;
  	}
}
