.images-grid__title {
    position: relative;
    padding-bottom: 20px;
    margin: 0;
    margin-bottom: -10px;
    line-height: 0.75em;
}
.images-grid__title::after {
    content: " ";
    display: block;
    height: 1px;
    width: 24px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #25282a;
}

.images-grid__content .individual-image__wrapper {
    margin-top: 30px;
}