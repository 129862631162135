.team-bio-card {
    padding-top: 30px;
}

.team-bio-card__profile-image {
    max-width: 100%;
    height: auto;
}

.team-bio-card__image-link,
.team-bio-card__image-link a {
    font-size: 0;
}

.team-bio-card__name {
    margin-top: 10px;
}

.team-bio-card__name,
.team-bio-card__name a {
    display: inline-block;
    color: #004f59;
}

.team-bio-card__name:hover,
.team-bio-card__name a:hover {
    text-decoration: underline;
    color: #004f59;
    text-decoration-color: #5c068c;
}

.team-bio-card__role {
    color: #25282a;
    margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
    .team-bio-card__name {
        margin-top: 0;
    }    
}