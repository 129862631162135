.display-1 {
    font-family: "CenturyStd-Book";
    font-size: 112px;
}

.display-2 {
    font-family: "CenturyStd-Book";
    font-size: 72px;
}

h1,
.heading-1 {
    font-family: "CenturyStd-Book";
    font-size: 48px;
}

h2,
.heading-2 {
    font-family: "AzoSans-Bold";
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
h3,
.heading-3 {
    font-family: "CenturyStd-Book";
    font-size: 32px;
}
h4,
.heading-4 {
    font-family: "AzoSans-Regular";
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #6A737B;
}
h5,
.heading-5 {
    font-family: "AzoSans-Bold";
    font-size: 20px;
}

.body-copy {
    font-family: 'AzoSans-Light';
    font-size: 18px;
    line-height: 32px;
}

.small-copy {
    font-family: 'AzoSans-Light';
    font-size: 12px;
    line-height: 16px;
}
@media(max-width: 576px) {
    .display-1 {
        font-size: 36px;
    }
    
    .display-2 {
        font-size: 28px;
    }
    h1,
    .heading-1 {
        font-size: 32px;
    }
    h2,
    .heading-2 {
        font-size: 20px;
    }
    h3,
    .heading-3 {
        font-size: 24px;
    }
    h4,
    .heading-4 {
        font-size: 16px;
    }
    h5,
    .heading-5 {
        font-size: 16px;
    }

    .body-copy {
        font-family: 'AzoSans-Light';
        font-size: 16px;
        line-height: 24px;
        white-space: normal;
    }
    
    .small-copy {
        font-family: 'AzoSans-Light';
        font-size: 10px;
        line-height: 16px;
        white-space: normal;
    }
}