.ticker__title.body-copy {
    font-family: 'AzoSans-Bold';
    margin: 16px 0;
}
.ticker__item {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    margin-bottom: 16px;
}
.ticker__item__xbrl-container {
    padding-left: 16px;
}

.ticker__link-item,
.ticker__link-item a {
    color: #5C068C;
}
.ticker__link-item:hover,
.ticker__link-item a:hover {
    color: #25282a;
    text-decoration: none;
}
.ticker__link-item:active,
.ticker__link-item a:active {
    color: #9B26B6;
}
.ticker__icon {
    width: auto;
    height: 24px;
    margin-left: 16px;
}
.ticker__link-icon {
    font-size: 0;
}
@media only screen and (max-width: 576px) {
    .ticker__item {
        justify-content: space-between;
    }
}