.large-header {
	width: 100%;
	height: auto;
	overflow: hidden;
	position: relative;
}

@media only screen and (min-width: 1024px) {
	.large-header {
		height: 500px;
	}
	.large-header-text-wrapper {
		bottom: 90px;
	}
}

@media only screen and (min-width: 577px) and (max-width: 1023px) {
	.large-header {
		height: 370px;
	}
	.large-header-text-wrapper {
		bottom: 90px;
	}
}

.large-header-text-wrapper {
	position: absolute;
}

.large-header-text-wrapper {
	z-index: 10;
}

.large-header-title.display-1 {
	line-height: normal;
}

@media only screen and (max-width: 576px) {
	.large-header {
		height: auto;
		padding-top: 302px;
	}

	.large-header-text-wrapper {
		position: relative !important;
	}
	.large-header-title {
		margin-bottom: 4px;
	}

	.large-header-subtitle > h3 {
		margin-bottom: 0px;
	}
}
