.buttons__button-columns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.button-container {
    display: inline-block;
}
.button {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 10px 0;
    margin: 15px 15px 15px 0;
    width: 300px;
    height: 55px;
    box-sizing: border-box !important;
    border-radius: 2px;
    font-family: AzoSans-Bold;
}

/*Primary*/
.primary--normal {
    background-color: #008578;
    border: 2px solid #008578;
}

.primary--hover {
    background-color: #004f59;   
    border: 2px solid #004f59;
}

.primary--selected {
    background-color: #25282a;   
    border: 2px solid #25282a;
}

.primary--disabled {
    background-color: #b2bac4;   
    border: 2px solid #b2bac4;
}

/*Secondary*/
.secondary--normal {
    background-color: white;   
    color: #008578;
    border: 2px solid #008578;
}

.secondary--hover {
    background-color:#47d7ac; 
    border: 2px solid #47d7ac;
}

.secondary--selected {
    color: #5bc2e7;   
    background-color: white;
    border: 2px solid #5bc2e7;
}

.secondary--disabled {
    color: #b2bac4;   
    background-color: white;
    border: 2px solid #b2bac4;
}

/*Tertiary*/
.tertiary--normal {
    background-color: #686c71;
    color: white;
    border: 2px solid white;
    margin-left: 15px;
}

.tertiary--hover {
    background-color: #b2bac4;
    border: 2px solid white;   
    margin-left: 15px;
}

.tertiary--selected {
    background-color: #84898f;   
    border: 2px solid white;
    margin-left: 15px;
}

.tertiary--disabled {
    background-color: #686c71;   
    border: 2px solid white;
    opacity: 0.4;
    margin-left: 15px;
}

.tertiary-background {
    background-color: #686c71;
}

.buttons__section-title {
    font-family: AzoSans-Bold;
    color: #686c71;
    font-size: 16px;
    border-bottom: 2px solid #b2bac4;
    margin-top: 30px;
}
.buttons__page-title{
    margin-top: 60px;
    margin-bottom: 30px;
}

/*Labels*/
.label {
    display: block;
    font-family: AzoSans-Regular;
    padding-top: 5px;
    padding-bottom: 10px;
}

.label.label--normal {
    color: #1497E1;
}

.label.label--hover {
    color: #1FB4FF;
}

.label.label--selected {
    color: #0177C1;
}

.label.label--disabled {
    color: #B2BAC4;
}

@media(max-width: 576px) {
    .buttons__button-columns {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }
    .button {
        margin-left: 15px;
    }
}
