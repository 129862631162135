#ppm-desktop-navigation-container {
  position: fixed;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid rgba(0,0,0,.2);
  z-index: 9000;
}
/* added and removed from element based on scroll */
.ppm-desktop-nav-dropShadow{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

#ppm-nav-desktop-logo{
  /* width: 263px; */
  width: 100%;
}
#ppm-nav-desktop-search-icon{
  cursor: pointer;
  color: #6a737b;
  width: 23px;
}
#ppm-nav-desktop-cross-icon{
  cursor: pointer;
  color: #6a737b;
  width: 23px;
  padding: 0 0 !important;
}
/* cross icon to appear when clicking on the search icon */
#ppm-nav-desktop-search-cross-list-item{
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  padding-left: 0px !important;
  padding-top: 8px;
  cursor: pointer;
}

/* Search container */
.ppm-nav-desktop-search-container-center-align-div{
  max-width: 1440px;
  margin: auto;
  padding-left: 15px;
  top: 105px;
  position: relative;
}

.ppm-nav-desktop-search-container{
  position: fixed;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  width: calc(100% - 30px);
  max-width: 1410px;
  height: 80px;
  background-color: white;
  margin-right: auto;
  z-index: 8999;
  box-shadow: 0px 2px 5px 0 rgba(0,0,0,0.5); 
}
.ppm-nav-desktop-search-container > form{
  width: calc(100% - 170px);
}
.ppm-nav-desktop-search-container > form > input{
  width: calc(100% - 40px);
  margin-left: 20px;
  font-size: 18px;
  /* designs specify 1.25em but that would be 20px since root font size is 16px, 
  set it to explicit since that's the body copy pixel size 18px */
  font-family: "AzoSans-Light";
  letter-spacing: .8px;
  line-height: 2em;
  /* 32px */
  border: none;
  background-color: white;
}
.ppm-nav-desktop-search-container > form > input:focus{
  outline: none;
  background-image: none !important;
  background-color: white !important;
}
.ppm-nav-desktop-search-container > form > input::placeholder{
  color: #6A737B;
  font-size: 18px;
  font-family: "AzoSans-Light";
  letter-spacing: .8px;
  line-height: 32px;
}
.ppm-nav-desktop-search-container-button{
  cursor: pointer;
  background-color: #008578;
  height: 80px;
  width: 171px;
  display: flex;
  align-items: center;
  margin-left: auto;

}
#ppm-nav-desktop-search-container-button-text{
  color: white;
  padding: 10px;
  padding-left: 35px;
  padding-right: 10px;
  font-family: "AzoSans-Regular";
  letter-spacing: .8px;
}

/* search icon in search container */
#ppm-nav-desktop-search-icon-search-container-image{
}

.larger-ppm-header {
  height: 105px;
  transition: height .35s ease;
}
.shrink-ppm-header {
  height: 70px;
  transition: height .35s ease;
  /* -webkit-transition: height 70px 1s ease; */ 
}

/* Main nav */

#ppm-nav-desktop {
  position: relative;
  background-color: white;
  z-index: 9001;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  flex-direction: column;
  display: flex;
  justify-content: center;
}
#ppm-nav-desktop>ul {
  margin: 0px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap; 
}
#ppm-nav-desktop > ul > li {
  margin: 0;
  padding: 0 22.5px;
  position: relative;
  align-self: center;
}
#ppm-nav-desktop > ul > li:first-child {
  padding-left: 15px;
  margin-right: auto;
}

#ppm-nav-desktop > ul > li:last-child {
  /* padding-left: 25px; */
  /* margin-right: auto; */
  /* above section is if you want a hard coded distance between the icons and the menu items */
  margin-left: auto;
  margin-right: 30px;
}

/* Remove native browser styles for links */
#ppm-nav-desktop a {
  margin: 0;
  text-decoration: none;
}

#ppm-nav-desktop > ul > li > ul {
  display: inline-block;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  padding: 12px 20px 22.5px 20px;
  background-color: #5BC2E7;
  text-align: left;
  position: absolute;
  left: 50%;
  margin-left: -110px;
  width: 220px; 
  transition: all  1s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
}
.top-70{
  top: 48px;
}
.top-105{
  top: 66px;
}
#ppm-nav-desktop > ul > li:hover > ul {
    opacity: 1;
    visibility: visible;
}
#ppm-nav-desktop > ul > li > ul:before {
    content: "";
    display: block;
    border-color: transparent transparent #5BC2E7 transparent;
    border-style: solid;
    border-width: 10px;
    /* The border on the drop down box  */
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -10px;
}

#ppm-nav-desktop > ul > li > ul > li > a {
    color: rgb(50, 50, 50);
    background-color: #5BC2E7;
    display: block;
    -webkit-transition: font-weight 0.5s ease;
    -moz-transition: font-weight 0.5s ease;
    -o-transition: font-weight 0.5s ease;
    transition: font-weight 0.5s ease;
}

/* Desktop First level Link  */
.firstLevelLinkDesktop {
  text-transform: uppercase;
  color: #6a737b;
  font-family: "AzoSans-Bold";
  font-size: 16px;
  letter-spacing: 4px;
  white-space:nowrap;
  line-height: 26px;	
  text-align: right;
}
.firstLevelLinkDesktop:hover {
  color: rgb(0, 0,0);
}
.firstLevelLinkDesktop.active {
  color: #004f59;
}

.secondLevelLink {
  font-family: "AzoSans-Bold";	
  font-size: 18px;	
  font-weight: 500;	
  line-height: 20px;
  padding: 10px 0 10px 0;
}
.ppm-second-level-desktop-list-item:not(:first-child) {
  padding-top: 8px;
}

.secondLevelLink:hover {
  text-decoration: underline !important;
}
.secondLevelLink.active {
  color: white !important;
}
.thirdLevelLink {
  font-family: "AzoSans-Regular";	
  font-weight: 500;	
  font-size: 15px;	
  line-height: 16px;
  padding: 5px 0 5px 0;
}
.thirdLevelLink:hover {
  text-decoration: underline !important;
}
.thirdLevelLink.active {
  color: white !important;
}