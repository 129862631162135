.overlay {
    position: relative;
    overflow: hidden;
    height: calc(100%);
}
.overlay:before, .overlay:after {
    content: "";
    display: block;
    position: absolute;
}

/*
    Left side overlays
*/
/* Bottom Left Overlay */
.left-side:before {
    width: 10000px;
    height: 150%;
    background-color: rgba(255, 255, 255, 0.58);
    /* top: 100%; PPM-353*/
    top: -5%;
    z-index: 2;
}
.left-side:before {
    transform-origin: top left;
    transform: rotate(25deg);
}

/* Top Left Overlay */
.left-side:after {
    transform-origin: bottom left;
    transform: rotate(-30deg);
}
.left-side:after {
    width: 10000px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    left: 0;
    top: -43%;
    z-index: 3;
}
/****/

/*
    Right side overlays
*/
/* Top Right overlay */
.right-side:before {
    transform-origin: bottom right;
    transform: rotate(25deg);
}
.right-side:before {
    width: 10000px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.58);
    left: initial;
    right: 0;
    top: -51%;
    transform-origin: bottom right;
    z-index: 2;
    transform: rotate(25deg)
}
/* Bottom Right overlay */
.right-side:after {
    transform-origin: top right;
    transform: rotate(-25deg);
}
.right-side:after {
    width: 10000px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    left: initial;
    right: 0;
    top: 49%;
    z-index: 3;
}
/****/

/*
    Both sides overlays
*/
/* Bottom Left overlay */
.left-right-side:before {
    width: 10000px;
    height: 150%;
    background-color: rgba(255, 255, 255, 0.58);
    /* top: 100%; PPM-353*/
    top: -5%;
    z-index: 2;
}
.left-right-side:before {
    /* transform: rotate(15deg); */
    /* TODO Remove once PPM-353 is resolved - may be additional tweaks*/
    transform-origin: top left;
    transform: rotate(25deg);
}

/* Top left overlay */
.left-right-side:after {
    /* transform: rotate(155deg); */
    transform-origin: bottom left;
    transform: rotate(-30deg);
}
.left-right-side:after {
    width: 10000px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    left: 0;
    top: -43%;
    z-index: 3;
}

/* Top Right Overlay */
.left-right-side #sub-wrapper::before {
    transform-origin: bottom right;
    transform: rotate(25deg);
}
.left-right-side #sub-wrapper::before {
    width: 10000px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.58);
    left: initial;
    right: 0;
    top: -51%;
    transform-origin: bottom right;
    z-index: 2;
    transform: rotate(25deg)
}
/* Bottom Right Overlay */
.left-right-side #sub-wrapper::after {
    transform-origin: top right;
    transform: rotate(-25deg);
}
.left-right-side #sub-wrapper::after {
    width: 10000px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    left: initial;
    right: 0;
    /* top: 75%; */
    top: 49%;
    z-index: 3;
}
/****/
/* Removes overlays when viewport is less than media query */
/* mobile */
@media only screen and (max-width: 576px) {
    .left-side::before, .left-side::after,
    .right-side::before, .right-side::after,
    .left-right-side::before, .left-right-side::after {
        display: none;
    }
}
/****/

/* Fix issue with any content on top */
#sub-wrapper .col-12, #sub-wrapper .col-6, #sub-wrapper .col-4 {
    z-index: 5;
} 