.page-title {
    margin-top: 60px;
}
.col-header {
    display: block;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'AzoSans-Bold';
}

.section-header {
    color: #6a737b;
    font-family: 'AzoSans-Bold';
    border-bottom: 1px solid #6a737b;
    margin-top: 30px;
}

.property_name, 
.property_value {
    font-family: 'AzoSans-Light';
    color:#6a737b;
}

.demo_text_container {
    overflow: hidden;
}
.demo_text {
    display: block;
    white-space: nowrap;
}

@media(max-width: 576px) {
    .demo_text {
        margin-top: 20px;
    }
}