.insights-promo__title {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    line-height: .75;
}

.insights-promo__title::after {
    content: " ";
    display: block;
    height: 1px;
    width: 24px;
    position: absolute;
    left: 0;
    bottom: 0;
}
.ppm-insights-promo-grid-container{
    display: grid;
    grid-template-columns: 3;
    grid-gap: 15px;
    grid-gap: 15px 15px;
}

/*Mobile Styles*/
@media (max-width: 576px) {
    .ppm-insights-promo-grid-container{
        display: grid;
        grid-template-rows: auto;
        grid-gap: 15px 15px;
    }
  }
  
/* Tablet Styles */
@media (min-width: 576px) {
    .ppm-insights-promo-grid-container{
        display: grid;
        /* During a design review, it was decided to have the 
        grid go from a three column directly to a one column layout */
        /* grid-template-columns: repeat(2, auto); */
        grid-template-columns: auto;
        grid-gap: 15px 15px;
    }
}
  
/* Desktop styles  */
@media (min-width: 992px) {
    .ppm-insights-promo-grid-container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px 15px;
    }
}