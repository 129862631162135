.team-bio-grid__title {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 15px;
    line-height: 0.75em;
}
.team-bio-grid__title::after {
    content: " ";
    display: block;
    height: 1px;
    width: 24px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #25282a;
}
.team-bio-grid__description-body {
    margin-bottom: 0;
}
/* add link styles to the rich text section */
.team-bio-grid__description-body a{
    color: #5C068C;
}
.team-bio-grid__description-body a:hover{
    /* PPM-380 */
    text-decoration: none;
    color: #25282a;
}

.team-bio-grid__description-wrapper {
    margin-bottom: 30px;
}
.team-bio-grid__description-title {
    text-transform: uppercase;
}
