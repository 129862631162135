.basics-rich-text-textarea {
    margin: 0;
}

/* PPM-380 */
/* add link styles to the rich text section */
.basics-rich-text-textarea a{
    color: #5C068C;
}
.basics-rich-text-textarea a:hover{
    text-decoration: none;
    color: #25282a;
}

/* PPM-380 */
/* add link styles to the rich text section */
.basics-rich-text-textarea a{
    color: #5C068C;
}
.basics-rich-text-textarea a:hover{
    text-decoration: none;
    color: #25282a;
}
