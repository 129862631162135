/* Heading style 3 */
.ppm-rich-text-cta-ui-title-container{
    /* TODO Remove once padding multiplier is there */
    /* padding-top: 60px; */
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
}
.ppm-rich-text-cta-ui-title{
    margin-bottom: 0;
    line-height: 24px;
    text-transform: uppercase;
}
.ppm-rich-text-cta-ui-title-container::after{
    content: " ";
    display: block;
    height: 1px;
    width: 24px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background-color: #25282a;
}
/* body copy */
.ppm-rich-text-cta-ui-body__container{
    padding-left: 0px;
}
.ppm-rich-text-cta-ui-body{
    font-family: 'AzoSans-Light';
    font-size: 1.25em;  /*  (18px) */
    line-height: 2em;  /*  (32px) */
    /* Remove explicit max width */
    /* https://productiveedge.atlassian.net/browse/PPM-461 */
    /* max-width: 545px; */
    overflow-wrap: normal;
}

/* PPM-380 */
/* add link styles to the rich text section */
.ppm-rich-text-cta-ui-body a{
    color: #5C068C;
}
.ppm-rich-text-cta-ui-body a:hover{
    text-decoration: none;
    color: #25282a;
}


.ppm-rich-text-cta-ui-button-link__container{
    padding-left: 0px;
    padding-top: 18px;
    /* Removed for padding multiplier option */
    /* padding-bottom: 65px; */
}

/* Primary Button Styles */
.ppm-primary-button{
    background-color: #008578;
    color: white;
    border: 2px solid #008578;
}
.ppm-primary-button:hover{
    color: white;
    background-color: #004f59;
    border: 2px solid #004f59;
}
.ppm-primary-button:active{
    color: white;
    background-color: #25282a;
    border: 2px solid #25282a;
}
.ppm-primary-button:focus{
    color: white;
    background-color: #25282a;
    border: 2px solid #25282a;
}
/* Not used for this component */
/* .ppm-primary-button:disabled{
    color: #b2bac4;
    background-color: white;
    border: 2px solid #b2bac4;
} */
.ppm-rich-text-cta-ui-button-link{
    display: inline-block;
    text-decoration: none;
}
.ppm-rich-text-cta-ui-button-link:hover{
    text-decoration: none;
    color: white;
}
.ppm-rich-text-cta-ui-button-link:active{
    text-decoration: none;
}
/* Secondary Button Styles */
.ppm-secondary-button{
    background-color: white;
    color: #008578;
    border: 2px solid #008578;
}
.ppm-secondary-button:hover{
    color: white;
    background-color: #47d7ac;
    border: 2px solid #47d7ac;
}
.ppm-secondary-button:active{
    color: #5bc2e7;
    background-color: white;
    border: 2px solid #5bc2e7;
}
/* Not used for this component */
/* .ppm-secondary-button:disabled{
    color: #b2bac4;
    background-color: white;
    border: 2px solid #b2bac4;
} */
.ppm-rich-text-cta-ui-button-link{
    display: inline-block;
    text-decoration: none;
}
.ppm-rich-text-cta-ui-button-link:hover{
    text-decoration: none;
    color: white;
}
.ppm-rich-text-cta-ui-button-link:active{
    text-decoration: none;
}

/* Tertiary Button Styles */
.ppm-tertiary-button{
    /* background-color: #686c71; */
    background-color: transparent;
    color: white !important;
    border: 2px solid white;
}
.ppm-tertiary-button:hover{
    /* background-color: RGBA(178, 186, 196, .25); */
    background-color: RGBA(255, 255, 255, .15);
    color: white !important;
}
.ppm-tertiary-button:active{
    /* background-color: RGBA(178, 186, 196, .5); */
    background-color: RGBA(255, 255, 255, .3);
    color: white !important;
}
/* Not used for this component */
/* .ppm-tertiary-button:disabled{
    opacity: 0.4;
    background-color: #686c71;
} */