.fund-group {
    padding-top: 45px;
}
.fund-group:not(:first-child) {
    padding-top: 55px;
}
.fund-group__title {
    color: #6a737b;
    margin-bottom: 16px;
    line-height: 26px;
    letter-spacing: 2.17px;
}