/* used when displaying the mobile navigation to dim background content */
#ppm-overlay{
  height:100vh;
  width:100vw;
  display: none;
  background-color: black;
  opacity: .5;
  position: absolute;
}
#ppm-mobile-navigation-container{
  position: fixed;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid rgba(0,0,0,.2);
  z-index: 9000;
  height: 80px;
}

.ppm-mobile-nav-dropShadow{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

#ppm-nav-mobile {
  z-index: 9001;
  width: 100%;
  height: 80px;
  display: flex;
  z-index: 9001;
}
/* TODO REmove */
/* #ppm-nav-mobile a{
  text-decoration: none;
} */
#ppm-nav-mobile > #ppm-nav-mobile-collapsed-ul{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  width: 100%;
  padding: 0px;
}
#ppm-nav-mobile ul {
  list-style: none;
}
#ppm-nav-logo-mobile-list-item{
  float: left;
  padding-left: 15px;
}
#ppm-nav-mobile-logo{
  width: 173px;
}
/* container for the search icon and the burger menu */
#ppm-nav-mobile-right-icon-list{
  display: flex;
  align-items: center;
}
#ppm-nav-mobile-search-icon-list-item{
  opacity: 1;
  visibility: visible;
  /* opacity: 0;
  visibility: hidden; */
  padding-top: 1px;
  padding-right: 19px;
  cursor: pointer;
}
#ppm-nav-mobile-search-cross-list-item{
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    height: auto;
    width: auto;
    min-width: 50px;
    min-height: 50px;
    position: fixed;
    right: 3px;
    top: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
    /* Added to nudge the cross into better alignment with the palaceholder text */
    margin-top: 3px;
}
/* cross that turns from search icon */
#ppm-nav-mobile-search-cross-back-slash{
  background-color: #008578;
  width: 20px;
  height: 2px;
  transform: rotate(45deg);
  font-size: 14px;
  position: absolute;
  right: auto;
  top: auto;
  /* right: 15px;
  top: 27px; */
}
#ppm-nav-mobile-search-cross-forward-slash{
  background-color: #008578;
  width: 20px;
  height: 2px;
  transform: rotate(135deg);
  font-size: 14px;
  position: absolute;
  right: auto;
  top: auto;
  /* right: 15px;
  top: 27px; */
}
#ppm-nav-mobile-search-container{
  visibility: hidden;
  z-index: 9000;
  opacity: 0;
  background-color: white;
  width: 100vw;
  position: absolute;
  height: 80px;
  top: 81px;
  box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.5); 
}
#ppm-nav-mobile-search-container-ul{
  margin: 0px;
  padding: 0px;

}
#ppm-nav-mobile-search-input-list-item{
  margin: 0px;
  padding-top: 25px ;
  padding-right: 0px ;
  padding-bottom: 0px ;
  /* set to 15 to align with other content */
  padding-left: 15px ;

}
#ppm-nav-mobile-search-input-list-item > form > input{
  font-size: 26px;
  font-family: "AzoSans-Bold";
  letter-spacing: 2px;
  border: none;
  width: calc(100% - 50px);
}
#ppm-nav-mobile-search-input-list-item > form > input:focus{
  outline: none;
  background-color: white !important;
  background-image: none !important;
}

/* custom behavior css */
.ppm-mobile-page-title-arrow-ul{
  height: 80px;
  border-bottom: 1px solid rgba(0,0,0,.2);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  cursor: pointer;
  padding: 0px;
  /* padding-bottom: 5px; */
  margin-bottom: 10px;
}

.ppm-mobile-title-svg-arrow{
  height: 15px;
  transform: rotate(180deg);
  padding-right: 30px;
  padding-left: 10px;
  margin-bottom: 6px;
}
.ppm-mobile-title{
  cursor: pointer;
  font: 16px "AzoSans-Regular"; 
  line-height: 20px;
  color: white;
}
/* right svg arrow styles for all containers */
.ppm-mobile-go-to-next-level-right-svg-arrow{
  height: 15px;
  cursor: pointer;
  margin-top: 5px;
  margin-right: 15px;
  float: right;
}

.ppm-mobile-title:hover{
  text-decoration: underline;
}
/* Mobile page of unordered list item links to go to next mobile page of links */
.ppm-mobile-page-ul-container{
  position: fixed;
  height: 100%;
  width: 87vw;
  left: 100vw;
  padding: 0px;
  top: 0px;
  transition: left .35s ease;
}
/* Ppm mobile page link items */
.ppm-nav-mobile-page-li{
  padding: 10px 10px 10px 30px;
  white-space: normal;
  /* display: flex; */
  /* flex-wrap: wrap; */
}
.ppm-nav-mobile-page-li > a{
  font-family: "AzoSans-Regular";	
  font-size: 18px;	
  line-height: 20px;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

/* used to change the cross to white for 2nd 3rd and 4th level */
.background-color-white{
  background-color:  white !important;
}

/* Both classes are to prevent event.target from being the g or path element nested inside the svg */
#ppm-nav-mobile g{
  pointer-events: none;
}
#ppm-nav-mobile path{
  pointer-events: none;
} 

#ppm-nav-burger-menu-mobile-list-item{
  padding-right: 15px;
}

/* burger menu */
#ppm-burger-menu {
  position: relative;
  z-index: 9008;
  display: block;
  width: 26px;
  height: 28px;
  background: transparent;
  border: none;
  cursor: pointer;
}

/* navigation menu transform to cross from burger */
#ppm-burger-menu:hover,
#ppm-burger-menu:focus,
#ppm-burger-menu:active {
  outline: none;
}
#ppm-burger-menu.is-closed:before {
  content: "";
  display: block;
  width: 100px;
  font-size: 13px;
  color: #004F59;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
}
#ppm-burger-menu.is-closed:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  transform: translate3d(-100px, 0, 0);
  transition: all 0.35s ease-in-out;
}

#ppm-burger-menu.is-closed .ppm-burger-menu-top,
#ppm-burger-menu.is-closed .ppm-burger-menu-middle,
#ppm-burger-menu.is-closed .ppm-burger-menu-bottom,
#ppm-burger-menu.is-open .ppm-burger-menu-top,
#ppm-burger-menu.is-open .ppm-burger-menu-middle,
#ppm-burger-menu.is-open .ppm-burger-menu-bottom {
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
}
#ppm-burger-menu.is-closed .ppm-burger-menu-top,
#ppm-burger-menu.is-closed .ppm-burger-menu-middle,
#ppm-burger-menu.is-closed .ppm-burger-menu-bottom {
  background-color: #008578;
}
#ppm-burger-menu.is-closed .ppm-burger-menu-top {
  top: 6px;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
#ppm-burger-menu.is-closed .ppm-burger-menu-middle {
  top: 48%;
}
#ppm-burger-menu.is-closed .ppm-burger-menu-bottom {
  bottom: 5px;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
/* removed due to bad on hover experience for mobile touch experience PPM-160 */
/* #ppm-burger-menu.is-closed:hover .ppm-burger-menu-top {
  top: 2px;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
} */
/* #ppm-burger-menu.is-closed:hover .ppm-burger-menu-bottom {
  bottom: 0;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
} */
#ppm-burger-menu.is-open .ppm-burger-menu-top,
#ppm-burger-menu.is-open .ppm-burger-menu-middle,
#ppm-burger-menu.is-open .ppm-burger-menu-bottom {
  background-color: black;
  width: 20px;
}
#ppm-burger-menu.is-open .ppm-burger-menu-top,
#ppm-burger-menu.is-open .ppm-burger-menu-bottom {
  top: 50%;
  margin-top: -2px;
}
#ppm-burger-menu.is-open .ppm-burger-menu-top {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
}
#ppm-burger-menu.is-open .ppm-burger-menu-middle {
  display: none;
}
#ppm-burger-menu.is-open .ppm-burger-menu-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transform: rotate(-45deg);
  transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
}
#ppm-burger-menu.is-open:before {
  content: "";
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  transform: translate3d(0, 0, 0);
  transition: all 0.35s ease-in-out;
}
#ppm-burger-menu.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  transform: translate3d(-100px, 0, 0);
  transition: all 0.35s ease-in-out;
}

/* first level container */
#ppm-nav-mobile-first-level-search-container{
    height: 80px;
    border: 1px solid rgba(0,0,0,.2);
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

#ppm-nav-mobile-first-level-links-ul{
  background-color: #5BC2E7;
  z-index: 9004;
}

#ppm-nav-mobile-first-level-search-container > form > input{
  background: transparent;
  border: none;
  font-family: "AzoSans-Medium";
  font-size: 21px;	
  line-height: 24px; 
}

/* first level list item */
.ppm-nav-mobile-first-level-li > a{
  color: black;
}
.ppm-nav-mobile-first-level-li > a:hover{
  color: black;
  text-decoration: underline;
}
.ppm-nav-mobile-first-level-li>a.active{
  color: white;
}

/* second level container ul */
.ppm-nav-mobile-second-level-links-ul{
  background-color: #004F59;
  z-index: 9005;
}
/* set custom color for second level link */
.ppm-nav-mobile-second-level-li > a{
  color: white;
}
.ppm-nav-mobile-second-level-li > a:hover{
  color: white;
  text-decoration: underline;
}
.ppm-nav-mobile-second-level-li > a.active{
  color: black;
}

/* third level container ul */
.ppm-nav-mobile-third-level-links-ul{
  background-color: #6A737B;
  z-index: 9006;
}

/* set custom color for third level link */
.ppm-nav-mobile-third-level-li > a{
  color: white;
}
.ppm-nav-mobile-third-level-li:hover > a{
  color: white;
  text-decoration: underline;
}
.ppm-nav-mobile-third-level-li>a.active{
  color: black;
}

/* fourth level container ul */
.ppm-nav-mobile-fourth-level-links-ul{
  background-color: #25282a;
  z-index: 9007;
}

/* set custom color for fourth level link */
.ppm-nav-mobile-fourth-level-li > a{
  color: white;
}
.ppm-nav-mobile-fourth-level-li > a:hover{
  color: #f4f4f4;
  text-decoration: underline;
}
.ppm-nav-mobile-fourth-level-li>a.active{
  color: #f4f4f4;
  text-decoration: underline;
}