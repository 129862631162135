.video-player__iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /*16:9 aspect ratio*/
}

.video-player__iframe-container iframe {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
}