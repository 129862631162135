.ppm-grid-card-play-icon{
    /* transform: scale(1.2); */
    height: 15%;
    top: 40px;
    right: 12%;
}
/* Mobile Styles
@media (max-width: 576px) {
    .ppm-grid-card-play-icon{
        transform: scale(.9);
        height: 15%;
        top: 8%;
        right: 12%;
    }
}

Tablet Styles
@media (min-width: 576px) {
    .ppm-grid-card-play-icon{
        transform: scale(1.1);
        height: 15%;
        top: 8%;
        right: 12%;
    }
}

Desktop styles
@media (min-width: 992px) {
    .ppm-grid-card-play-icon{
        transform: scale(1.2);
        height: 15%;
        top: 8%;
        right: 12%;
    }
} */
/* Top level card container */
.ppm-dynamic-content-card-container{
    display: inline-block;
    position: relative;
    /* set width to size to parent component */
    width: 100%;
}
/* remove the default styles for link */
.ppm-dynamic-content-grid-container>a{
    cursor: "poiner";
    text-decoration: "none";
    color: "initial";
}
.ppm-dynamic-content-grid-container>a:hover{
    cursor: "poiner";
    text-decoration: "none" !important;
    color: "initial";
}
/*Link that wraps the card*/
.ppm-grid-card-link{
    cursor: "poiner";
    text-decoration: "none";
    color: "black";
}
.ppm-grid-card-play-icon{
    position: absolute;
    object-fit: cover;
    /* set it so this element doesn't get a hover event */
    pointer-events: none;
}
/* Card Image */
.ppm-grid-card-image{
    width: 100%;
    opacity: 1;
}

.ppm-grid-card-overlay{
    height: auto;
    width: auto;
    background-color: black;
    position: absolute;
    opacity: .3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
/* set card image to change opacity on hover over container */
.ppm-grid-card-overlay:hover {
    opacity: 0;
}

.ppm-grid-card-title{
    display: inline-block;
    position: absolute;
    color: white;
    margin: 0px;
    /* set it so this element doesn't get a hover event */
    pointer-events: none;
}
.ppm-grid-card-title:after{
    content: "";
    height: 1px;
    width: 24px;
    background-color: white;
    display: block;
    position: absolute;
    left: 0;
}
.ppm-grid-card-desc{
    /* display: inline-block; */
    position: absolute;
    color: white;
    max-width: 65%;
    margin: 0px;
    /* set it so this element doesn't get a hover event */
    pointer-events: none;
}