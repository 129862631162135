
/*Mobile Styles*/
@media (max-width: 576px) {
    .ppm-dynamic-content-grid-container{
        display: grid;
        grid-template-rows: auto;
        grid-gap: 15px 15px;
    }
  }
  
  /* Tablet Styles */
  @media (min-width: 576px) {
    .ppm-dynamic-content-grid-container{
        display: grid;
        /* During a design review, it was decided to have the 
        grid go from a three column directly to a one column layout */
        /* grid-template-columns: repeat(2, auto); */
        grid-template-columns: auto; 
        grid-gap: 15px 15px;
    }
  }
  
  /* Desktop styles */
  @media (min-width: 992px) {
    .ppm-dynamic-content-grid-container{
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 15px 15px;
    }
  }
  .hideCardFromDom{
      display: none;
  }