.large-title__title-container {
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
}

.large-title__title-container:after {
    content: " ";
    display: block;
    height: 1px;
    width: 24px;
    position: absolute;
    bottom: 0;
    background-color: #25282a;
}

.large-title__title_text {
    color: #25282a;
    margin: 0;
    line-height: 48px;
}

@media only screen and (max-width: 768px) {
    .large-title__title_text {
        line-height: 40px;
    }
}

@media only screen and (max-width: 576px) {
    .large-title__title_text {
        line-height: 32px;
    }
}