.rich-text-subcategory-list-ui {
    padding-right: 45px;
}
.rich-text-subcategory-list-right-column{
    padding-left: 50px !important;
}
.rich-text-subcategory-list-ui__second-row-container{
    padding-top: 55px;
    padding-bottom: 34px;
    padding-left: 0px;
}
.rich-text-subcategory-list-ui__third-row-container{
    padding-top: 55px;
    padding-bottom: 34px;
    padding-left: 0px;
    margin-left: -31px;
}
.rich-text-subcategory-list-ui__first-row-container{
    padding-top: 55px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 45px;
    max-width:252px;
    display:table;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.4;
}
.rich-text-subcategory-list-item{
    color: #6a737b;
    line-height: 2em;
    line-height: 32px;
    list-style-type: disc;
    font-size: 18px;
    font-size: 1.25em;
    padding-bottom: 11px;
    letter-spacing: .6px;
    font-family: "AzoSans-Light";
}
.rich-text-section-title__title{
    font-family: "AzoSans-Bold";
    font-size: 1.25em; /* 20px; */
}

/* mobile */
.rich-text-subcategory-list-ui.mobile {
    padding-right: 15px;
}
.rich-text-subcategory-list-ui__second-row-container-mobile{
    padding-top: 0px;
    padding-bottom: 34px;
    padding-left: 0px;
}
.rich-text-section-title__title-mobile{
    font-family: "AzoSans-Bold";
    font-size: 1em 
    /* 16px; */
}
.rich-text-subcategory-list-ui__first-row-container-mobile{
    padding-top: 55px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.4;
}
.rich-text-subcategory-list-item-mobile{
    font-family: "AzoSans-Light";
    padding-left: 0px;
    list-style-type: disc;
    font-size: 16px;
    font-size: 1rem;
    color: #6a737b;
    line-height: 1.5em;
    /* (24px) */
}