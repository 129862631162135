.side-nav * {
    transition: none;
    transition: font-size 0.35s ease;
}
.side-nav {
    position: relative;
}
.side-nav__link:not(:first-child) {
    margin-top: 12px;
}

.side-nav__link {
    margin-right: 12px;
}

.side-nav__link-wrapper {
    display: inline;
    margin-bottom: 0;
}
.side-nav__link, 
.side-nav__link a {
    display: inline;
    color: #5C068C;
    transition: color 0.35s ease;
    text-transform: uppercase;
}

.subnav__link,
.subnav__link a {
    font-family: AzoSans-Bold, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16pt;
    letter-spacing: 3px;
}

.side-nav__right-arrow {
    font-size: 0;
}
.side-nav__right-arrow svg {
    width: 6px;
    height: 12px;
    stroke: #5C068C;
    stroke-width: 2px;
    transition: stroke 0.35s ease;
    position: relative;
    top: -2px;
    transform: translateY(-50%);
}

.side-nav__link.hover,
.side-nav__link.hover a {
    text-decoration: none;
    color: #25282a;
}

.side-nav__link.active,
.side-nav__link.active a {
    text-decoration: none;
    color: #9B26B6;
}

.side-nav__right-arrow.hover svg {
    stroke: #25282a;

}
.side-nav__right-arrow.active svg {
    stroke: #9B26B6;
}
@media only screen and (max-width: 768px) {
    .side-nav__link,
    .side-nav__link a {
        font-size: 18px;
    }
    .side-nav__right-arrow svg {
        top: 0;
    }
}