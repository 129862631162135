.globalTrans{
  /* changed to class so it's not applied in editing mode - messes with experience editor */
  transition: all .35s ease;
}
html{
  font-size: 16px;
  /* added to prevent the scroll bar on the right from bumping content to the left when appearing and disappearing on modal display */
  /* the problem with the item below is that it stops the disabling of scroll when modal appears */
  /* overflow-y: overlay; */
}
body{
  font-family: 'AzoSans-Light';
}

body p {
  font-size: 18px;
  line-height: 32px;
}
.noVertScroll{
  overflow-x: hidden;
  overflow-y: hidden;
}
/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm { display: none !important; }

.container {
    max-width: 1440px !important;
}
#navLogo{
  max-height: 50px;
}
/* layout components */

/*Mobile Styles*/
@media (max-width: 576px) and (min-width: 0px) {
  /* Main App Container */
  #ppm-main-website-content-container{
    padding-top: 105px; /* header size */
    /* the footer is even taller than in tablet with a buffer on table and mobile */
    /* min-height: calc(100vh - 252.17px);  */
    min-height: calc(100vh - 223.56px); 
  }
  .no-padding{
    padding: 0 0 !important;
  }
  /* Two Column Component */
  .ppm-layout-component-two-column-container-half{
    display: grid;
    grid-template-rows: fit-content(50%) fit-content(50%);
    /* grid-template-rows: 1fr 1fr; */
  }
  .ppm-layout-component-two-column-container-one-two{
    display: grid;
    grid-template-rows: fit-content(50%) fit-content(50%);
    /* grid-template-rows: 1fr 1fr; */
  }
  .ppm-layout-component-two-column-container-two-one{
    display: grid;
    grid-template-rows: fit-content(50%) fit-content(50%);
    /* grid-template-rows: 1fr 1fr; */
  }
  .ppm-layout-component-two-column-left{
  }
  .ppm-layout-component-two-column-right{
  }
  /* half half - one third two thirds - two thirds and one third - arrangement classes */
  .ppm-layout-component-two-column-half-half{
    /* width: 50% */
  }
  .ppm-layout-component-two-column-one{
    /* width: 33.33% */
  }
  .ppm-layout-component-two-column-two{
    /* width: 66.66% */
  }
}
/* Tablet Styles */
@media (min-width: 576px) and (max-width: 992px) {
  /* main app container */
  #ppm-main-website-content-container{
    padding-top: 105px; /* header size */
    /* the footer is even taller with a buffer on table and mobile */
    /* min-height: calc(100vh - 204px);  */
    min-height: calc(100vh - 176px); 
  }
  /* Global component top and bottom padding class */
  .one-column-top-bottom-padding{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .two-column-top-bottom-padding{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .three-column-top-bottom-padding{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  /* Two Column Component */
  /* Retain third arrangment for tablet for two column layouts */
  .ppm-layout-component-two-column-container-half{
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .ppm-layout-component-two-column-container-one-two{
    display: grid;
    grid-template-columns: 33.33% 66.66%;
  }
  .ppm-layout-component-two-column-container-two-one{
    display: grid;
    grid-template-columns: 66.66% 33.33%;
  }
  .ppm-layout-component-two-column-container{
  
  }
  .ppm-layout-component-two-column-left{
    
  }
  .ppm-layout-component-two-column-right{
    
  }
  
}
/* Desktop styles */
@media (min-width: 992px) {
  /* Main App Container */
  #ppm-main-website-content-container{
    padding-top: 105px; /* header size */

    min-height: calc(100vh - 149px); 
  }
  /* Global component top and bottom padding class */
  .one-column-top-bottom-padding{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .two-column-top-bottom-padding{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .three-column-top-bottom-padding{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  /* One Column Component */
  .ppm-one-column-layout-component-container{
    /* margin: 15px 0px; */
  }
   /* Two Column Component */
   .ppm-two-column-layout-component-container{
    /* margin: 15px 0px; */
  }
   .ppm-layout-component-two-column-container-half{
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .ppm-layout-component-two-column-container-one-two{
    display: grid;
    grid-template-columns: 33.33% 66.66%;
  }
  .ppm-layout-component-two-column-container-two-one{
    display: grid;
    grid-template-columns: 66.66% 33.33%;
  }
  /* Three Column Components */
  .ppm-layout-component-three-column-container{
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
  }
  .ppm-layout-component-three-column-left{

  }
  .ppm-layout-component-three-column-middle{

  }
  .ppm-layout-component-three-column-right{

  }
}

/*
 * spacing utility classes
 */
.fullWidth {
  max-width: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.marginTopSmall {
  display: inline-block;
  margin-top: 15px;
}
.marginTopMedium {
  display: inline-block;
  margin-top: 30px;
}
.marginTopLarge {
  display: inline-block;
  margin-top: 45px;
}

.marginBottomSmall {
  display: inline-block;
  margin-bottom: 15px;
}
.marginBottomMedium {
  display: inline-block;
  margin-bottom: 30px;  
}
.marginBottomLarge {
  display: inline-block;
  margin-bottom: 45px;  
}

/*
 * dislaimer/disclosure global style 
 */
.disclaimer {
  display: inline-block;
  font-family:"AzoSans-Light";
  font-size: 12px;
  font-size: 0.75rem;
  color: #6a737b;
  margin-bottom: 0px;
  /* line-height: 19px; */
  line-height: 16px
}
/* PPM-380 */
/* add link styles to the rich text section */
.disclaimer a {
  text-decoration: underline;
  color: #6a737b;
}
.disclaimer a:hover{
  color: #6a737b;
}
.disclaimer a:active{
  color: #6a737b;
}
.disclaimer a:focus{
  color: #6a737b;
}
