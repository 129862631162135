.individual-image__wrapper img,
.individual-image__image {
    width: 100%;
    height: auto;
}

.individual-image__image-label {
    margin-top: 15px;
    margin-bottom: 15px;
}
.individual-image__image-label-link {
    display: inline;
}
span.individual-image__image-label-link {
    font-size: 0;
}
.individual-image__image-label,
.individual-image__image-label a {
    color: #25282a;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.individual-image__image-label:hover,
.individual-image__image-label a:hover {
    color: #25282a;
}

/* PPM-380 */
/* add link styles to the rich text section */
.individual-image__wrapper > .body-copy a{
    color: #5C068C;
}
.individual-image__wrapper > .body-copy a:hover{
    text-decoration: none;
    color: #25282a;
}