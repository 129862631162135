/* Mobile styles */
@media (max-width: 767px) and (min-width: 0px) {
    /* Search Results List Container and Title */
    .ppm-search-in-page-container{

    }
    .ppm-search-results-list-container{
        max-width: 90%;
    }
    .ppm-search-results-list-title{
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

    /* Individual search results styles */
    .ppm-search-result-link-container{
        margin-bottom: 3em;
    }
    .ppm-search-result-container-link{

    }
    .ppm-search-result-container{

    }
    .ppm-search-result-title-and-type{
           
    }
    .ppm-search-result-title{
        margin-bottom: 1em;
    }
    .ppm-search-result-blurb{
        font-family: "AzoSans-Light";
        font-size: 1em;
        /* (16px) */
        Line-height: 1.5em;
        /* (24px) */
    }
    .ppm-search-result-type{

    }    
}
/* Desktop and Table styles */
@media (min-width: 768px) {
    /* Search Results List Container and Title */
    .ppm-search-in-page-container{

    }

    .ppm-search-results-list-container{
        max-width: 65%;
    }
    .ppm-search-results-list-title{
        margin-top: 2em;
        margin-bottom: 3em;
    }

    /* Individual search results styles */
    .ppm-search-result-link-container{
        margin-bottom: 5em;
    }
    .ppm-search-result-container-link{
        
    }
    .ppm-search-result-container{
        display: grid;
        grid-template-columns: 20% 70%; 
        /* had to change from 10% to 20% due to collision on tablet in portrait mode */
        grid-gap: 0% 20%;
    }
    .ppm-search-result-title-and-type{
        display: grid;
        grid-template-rows: 70% 30%;
        grid-gap: 10% 0%;
    }
    /* add a different grid template if the title doesn't exist */
    .ppm-search-title-is-empty{
        display: grid;
        grid-template-rows: 100%;
    }
    .ppm-search-result-title{
    }
    .ppm-search-result-blurb{
        font-family: "AzoSans-Light";
        font-size: 1.25em;
        /* (18px) */
        Line-height: 2em;
        /* (32px) */
    }
    .ppm-search-result-type{

    }
}
/* Global Styles */
.ppm-search-result-link-container:hover .ppm-search-result-title{
    text-decoration: underline;
}
.noTitleHoverOnTypeThen:hover .ppm-search-result-type{
    text-decoration: underline;
}

.ppm-search-result-container-link,
.ppm-search-result-container-link:hover,
.ppm-search-result-container-link:active,
.ppm-search-result-container-link:focus
{
    color: black;
    text-decoration: none;
}

.ppm-search-result-blurb{
    margin: 0;
}
.hideResultFromDom{
    display: none;
}