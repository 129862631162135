.donut-chart {
	padding: 40px 0;
}

.donut-chart .title {
	text-transform: uppercase;
}

.donut-chart .chart, .donut-chart .info {
	display: inline-block;
}

.donut-chart .basics-rich-text-wrapper {
    padding: 30px 30px 0px 0px;
}

@media only screen and (max-width: 576px){
	.donut-chart {
		padding: 0 !important;
		margin: 0 !important;
	}
	.donut-chart .basics-rich-text-wrapper {
	    padding: 0;
	}
}

@media only screen and (max-width: 991px) {
	.donut-chart .info {
	    margin-left: 0;
	    margin-top: 40px;
	}

	.donut-chart .chart, .donut-chart .info {
		display: block;
	}
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
	.donut-chart-canvas {
		margin: 0 auto;
		width: 50% !important;
		height: auto !important;
	}
}
.legend {
	margin-top: 20px;
}

.legend .legend-item {
	display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.legend .legend-item .color-item {
	display: inline-block;
	width: 30px;
	height: 24px;
	border-radius: 2px;
	margin-right: 15px;
}

.legend .legend-item .legend-value {
	display: inline-block;
} 

.superscript{
    font-size: 0.6em;
    vertical-align: super;
}