.small-header {
	width: 100%;
	height: auto;
}

@media only screen and (min-width: 1024px) {
	.small-header-wrapper {
		padding: 26.5925px 0px;
	}
	.small-header-title {
		padding-bottom: 33.5925px;
		margin-bottom: 29.5925px;
		position: relative;
	}
	.ppm-breadcrumbs-title{
		display: inline-block;
		padding-left: .2em;
	}
	.ppm-breadcrumbs-caret{
		cursor: pointer;
		height: 24px;
		width: 10px;
	}
	.negMargin{
		margin-bottom: -14px;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 577px) {
	.small-header-wrapper {
		padding: 17.5px 0px;
	}	
	.small-header-title {
		padding-bottom: 24.5px;
		margin-bottom: 30.5px;
		position: relative;
	}
	.small-header .display-2 {
		font-size: 28px;
	}
	.ppm-breadcrumbs-title{
		display: inline-block;
		padding-left: .2em;
	}
	.ppm-breadcrumbs-caret{
		cursor: pointer;
		height: 18px;
		width: 10px;
	}
}

@media only screen and (max-width: 576px) and (min-width: 0px) {
	.small-header-wrapper {
		padding: 11.17px 0px;
	}	
	.small-header-title {
		padding-bottom: 19.17px;
		margin-bottom: 23.17px;
		position: relative;
	}
	.small-header .display-2 {
		font-size: 28px;
	}
	.ppm-breadcrumbs-title{
		display: inline-block;
		padding-left: .2em;
	}
	.ppm-breadcrumbs-caret{
		cursor: pointer;
		height: 18px;
		width: 10px;
	}
}

.small-header-title:after {
    content: " ";
    display: block;
    height: 1px;
    width: 24px;
    position: absolute;
    left: 0;
	bottom: 0;
	background-color: white;
}

.ppm-breadcrumbs-container{
	display: inline-block
}

.ppm-breadcrumbs-title{
	display: inline-block;
}

.ppm-breadcrumbs-title-link{
	text-decoration: none !important;
	color: inherit;
}
.ppm-breadcrumbs-title-link:hover{
	color: inherit;
}
.ppm-breadcrumbs-title-link:active{
	color: inherit;
}
