.fund__accordion-btn * {
    transition: none;
    transition: font-size 0.35s ease;
}
.fund__wrapper {
    border-bottom: 1px solid #25282a;
    padding: 16px 0;
}
.fund__accordion-btn {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin: 0;
}

.fund__fund-name {
    cursor: pointer;
    color: #5C068C;
    margin-right: 12px;
    transition: color 0.35s ease;
}
.fund__fund-name.hover {
    color: #25282a;
    text-decoration: none;
}
.fund__fund-name.active {
    color: #9B26B6;
}
.fund__fund-name-arrow {
    stroke: #5C068C;
    stroke-width: 2px;
    transition: stroke 0.35s ease;
    width: 12px;
    height: 12px;
    cursor: pointer;
    transition: stroke 0.35s ease;
}
.fund__fund-name-arrow {
    transform-origin: center center;
    transform: rotate(90deg);
    transition: transform 0.35s ease;
}
.fund__fund-name-arrow.open {
    transform: rotate(270deg);
    transform-origin: center center;
}
.fund__fund-name-arrow.hover {
    stroke: #25282a;
}
.fund__fund-name-arrow.active {
    stroke: #9B26B6;
}
.fund__panel {
    padding: 0 16px;
    background-color: white;
    max-height: 0;
    transition: max-height 0.35s ease;
    overflow: hidden;
}
.fund__panel.open {
    max-height: 1000px;
}
.fund__ticker-item {
    display: block;
}

@media only screen and (max-width: 576px) {
    .fund__accordion-btn {
        justify-content: space-between;
    }
    .fund__panel {
        padding: 0;
    }    
}