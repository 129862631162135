
/* Global style for checkbox text and checkbox */
.ppm-modal-body-container{
    border-bottom: solid 1px rgba(0,0,0,.2);
}
.ppm-modal-checkbox{
    height: 20px;
    width: 20px;
    background-color: transparent;
}
.ppm-modal-checkbox-text{
    text-align: left;
    vertical-align: -webkit-baseline-middle;
}

/* If we want a third break point for the modal we can use both rules to specify a third state
@media only screen and (max-width: 600px) and (min-width: 400px)  {...} */

.ppm-modal-container{
    /* added to enable transition on showing and hiding the modal */
    visibility: hidden;
    opacity: 0;

    background-color: white;
    /* display: block; */
    z-index: 9100;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
}
.ppm-modal-dialog{
    /* added to enable transition on showing and hiding the modal */
    visibility: hidden;
    opacity: 0;

    padding: 40px;
    margin: 100px auto;
    background-color: white;
    position: relative;
    max-height: 80vh;
    text-align: center;
    z-index: 9101;
    width: 800px;
    max-width: calc(100% - 40px);
    position: relative;
    background-color: #ffffff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.3);
}
.ppm-modal-header{
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
}
.ppm-modal-header-dismiss-button-div{
    position: absolute;
    top: 12px;
    right: 12px;
}
.ppm-modal-header-dismiss-button{
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    /* display: inline-block; */
    /* fill: #25282a; */
    /* box-sizing: border-box; */
    border: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    line-height: 1.4;
    text-align: left;
    text-decoration: none;
}
.ppm-modal-header-dismiss-button:before{
    transform: rotate(45deg);
    background-color: #6a737b;
    content: "";
    position: absolute;
    height: 2px;
    top: 50%;
    width: 200%;
    left: -50%;
    margin-top: -1px;
}
.ppm-modal-header-dismiss-button:after{
    transform: rotate(-45deg);
    background-color: #6a737b;
    content: "";
    position: absolute;
    height: 2px;
    top: 50%;
    width: 200%;
    left: -50%;
    margin-top: -1px;
}
.ppm-modal-body-container{
    padding: 15px;
}

.ppm-modal-footer button{
    background-color: white;
    background-color: transparent;
    border-radius: 3px; 
    cursor: pointer;
    text-transform: uppercase;
}
.ppm-modal-footer-left-button{
    color: #008578;
    border: 2px solid #008578;
}
.ppm-modal-footer-right-button{
    color: black;
    border: 2px solid black;
}
.ppm-modal-footer-left-button:hover{
    color: white;
    background-color: #008578;
}
.ppm-modal-footer-right-button:hover{
    color: white;
    background-color: black;
}
.ppm-modal-footer-left-button:active{
    color: #5bc2e7;
    background-color: transparent;
    border:  2px solid #5bc2e7;
}
.ppm-modal-footer-right-button:active{
    color: #5bc2e7;
    background-color: transparent;
    border:  2px solid #5bc2e7;
}
.ppm-modal-footer-left-button:disabled{
    cursor: default;
    color: #b2bac4;
    background-color: transparent;
    border: 2px solid #b2bac4
}
.visibilityClass{
    visibility: visible;
    opacity: 1;
}

/* Mobile Phone */
@media (max-width: 576px) and (min-width: 0px) {
    .ppm-modal-dialog{
        padding: 10px;
        margin: 15px auto;
        background-color: white;
        position: relative;
        text-align: center;
        z-index: 9101;
        
        max-height: calc(100% - 30px);
        max-width: calc(100% - 30px);
        height: auto;
        width: auto;
        
        position: relative;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.3);
    }
    .modalHeading{
        /* As discussed in the design review this title is too big for mobile screens */
        /* font-size: 1.5em; 32px */
        font-size: 1.2em; 
        font-family: "CenturyStd-Book";
    }
    .ppm-modal-body-container div{
        overflow-y: auto;
        max-height: 25vh;
        margin: auto;
        text-align: left;
        font-family: "AzoSans-Light";
        font-size: 1em;
         /* (16px) */
        line-height: 1.5em;
         /* (24px) */
         border-bottom: 1px black;
    }
    .ppm-modal-checkbox-container{
        display: grid;
        grid-template-columns: fit-content(5%) fit-content(95%);
        padding: 15px;
    }
    .ppm-modal-checkbox{
        margin: 4px 10px 10px 0px;
        padding: 0px 5px 5px 0px !important;
    }
    .ppm-modal-checkbox-text{

    }
    .ppm-modal-footer{
        display: grid;
        grid-gap: 1em 0em;
        padding: 0px 15px 15px 15px;
    }
    .ppm-modal-footer button{
        height: 35px;
        width: 180px;
        font-size: 16px;   
    }
}
/* Tablet */
@media (max-width: 992px) and (min-width: 577px) {
    .modalHeading{
        /* As discussed in the design review this title is too big for mobile screens */
        /* font-size: 1.5em; 32px */
        font-size: 1.2em; 
        font-family: "CenturyStd-Book";
    }
    .ppm-modal-body-container div{
        overflow-y: auto;
        max-height: 35vh;
        margin: auto;
        text-align: left;
        font-family: "AzoSans-Light";
        font-size: 1em;
         /* (16px) */
        line-height: 1.5em;
         /* (24px) */
         border-bottom: 1px black;
    }
    .ppm-modal-checkbox-container{
        display: grid;
        grid-template-columns: fit-content(5%) fit-content(95%);
        padding: 15px;
    }
    .ppm-modal-checkbox{
        margin: 4px 10px 10px 0px;
        padding: 0px 5px 5px 0px !important;
    }
    .ppm-modal-checkbox-text{

    }
    .ppm-modal-footer{
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: center;
        grid-gap: 2em;
        width: 60%;
        margin: auto;
    }
    .ppm-modal-footer button{
        height: 35px;
        width: 180px;
        font-size: 16px;   
    }
}
/* Desktop */
@media (min-width: 993px) {
    .modalHeading{
        font-size: 2em; /* 32px */
        font-family: "CenturyStd-Book";
    }
    .ppm-modal-body-container div{
        overflow-y: auto;
        max-height: 45vh;
        margin: auto;
        text-align: left;
        font-family: "AzoSans-Light";
        font-size: 1.25em;
        /* (18px) */
        line-height: 2em;
        /* (32px) */
        border-bottom: 1px black;
    }
    .ppm-modal-checkbox-container{
        display: grid;
        grid-template-columns: fit-content(5%) fit-content(95%);
        grid-gap: 1em;
        padding: 15px;
    }
    .ppm-modal-checkbox{ 
        margin: 4px 10px 10px 0px;
        padding: 0px 5px 5px 0px !important;
    }
    .ppm-modal-footer{
        padding: 10px 15px 10px 15px;
        display: grid;
        grid-template-columns: fit-content(50%) fit-content(50%);
        justify-content: center; 
        grid-gap: 2em;
    }
    .ppm-modal-footer button{
        height: 40px;
        width: 200px;
        font-size: 20px; 
    }
}
/* Adjust space for body content if in tablet landscape mode*/
/* Tablet */
@media (orientation: landscape) {
    .ppm-modal-body-container div{
        max-height: 35vh;
    }
}
/* Tablet */
/*account for landscape mode on mobile phone  */
@media (max-width: 992px) and (min-width: 560px) and (max-height: 500px) and (orientation: landscape) {
    .ppm-modal-body-container div{
        max-height: 23vh;
    }
    .ppm-modal-dialog{
        padding: 10px;
        margin: 15px auto;
        background-color: white;
        position: relative;
        text-align: center;
        z-index: 9101;
        
        max-height: calc(100% - 30px);
        max-width: calc(100% - 30px);
        height: auto;
        width: auto;
        
        position: relative;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.3);
    }
    .ppm-modal-checkbox-container h5{
        font-size: 1em;
    }
    .ppm-modal-footer{
        padding: 10px 15px 10px 15px;
        display: grid;
        grid-template-columns: fit-content(50%) fit-content(50%);
        justify-content: center; 
        grid-gap: 2em;
    }
    .ppm-modal-footer button{
        height: 30px;
        width: 180px;
        font-size: 16px;   
    }
}