.rich-text-section-title__title-container{
    padding-top: 9px;
    padding-right: 0px;
    padding-bottom: 22px;
    padding-left: 0px;
    position: relative;
}

.rich-text-section-title__title_text {
    font-family:"AzoSans-Bold";
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 0px;
    line-height: 24px;
}

.rich-text-section-title__superscript {
    font-size: 0.6em;
    vertical-align: super;
    margin-left: 5px;
    text-transform: none;
}